import React from "react";
import ModalWindow from "@/custom_components/ModalWindow";
import withSpinner from "@/hoc/withSpinner";
import Form from "./Form";

const Panel = ({
  close,
  error,
  mode,
  price = 0,
  tax,
  submitSale,
  txnDetails,
  isLoadedPayrix,
  hideBackButton = false,
  children,
}) => {
  return (
    // terminal && terminal.id &&
    <ModalWindow position="tm-flex-medium tm-flex-top" className="uk-width-3-4@m">
      <div className="uk-padding-small uk-button-danger ">
        {hideBackButton ? null : (
          <a className="uk-float-left uk-link-text uk-light" onClick={close}>
            Back
          </a>
        )}
        <span className="uk-text-bold">Payment</span>
        <span className=" uk-margin-left uk-float-right">${price}</span>
      </div>

      <section className="uk-padding-small" id="parent-pay">
        <Form
          mode={mode}
          error={error}
          isLoadedPayrix={isLoadedPayrix}
          submitSale={submitSale}
          price={price}
          tax={tax}
          txnDetails={txnDetails}
        >
          {children}
        </Form>
      </section>
    </ModalWindow>
  );
};

export default Panel;
