import { noop, to2DP } from "@/constants/utility";
import ModalWindow from "@/custom_components/ModalWindow";
import Loader from "@/custom_components/Preloader/Preloader";
import SuccessPopup from "@/custom_components/SuccessPopup";
import useFetch from "@/hoc/useFetch";
import { withAuthentication } from "@/hoc/withAuthentication";
import withSpinner from "@/hoc/withSpinner";
import { warningAlert } from "@/utility/helpers";
import React, { useMemo, useState } from "react";
import { usePayrix } from "../Payrix/usePayrix";
import Panel from "../PayrixPayField/Panel";

const CustomerInvoice = ({ setShowSpinner, match, httpClient, history }) => {
  const invoiceID = match.params.id;

  const [sale, setSale] = useState();
  const [paymentError, setPaymentError] = useState();

  const {
    data: invoice,
    loading,
    status,
    error,
  } = useFetch({ url: `/invoice/customer/${invoiceID}`, autoFetch: !!invoiceID });

  const saleObject = useMemo(() => {
    return status == "complete" ? JSON.parse(invoice?.taskQueue?.json) : {};
  }, [status, loading]);

  const storeLogo = invoice?.integration?.repairshop?.storeLogoImg;

  const discount = saleObject?.discountAmount || saleObject?.manualDiscount || 0;

  const payrix = usePayrix({
    amount: to2DP(invoice?.price) * 100,
    tax: to2DP(invoice?.tax || 0) * 100,
    description: `Amount - $${to2DP(invoice?.price)}`,
    name: "Payment Details",
    color: "a6371e",
    merchant: invoice?.integration?.payrixMerchantId,
    tnxType: "auth",
    mode: "token",
    apiKey: /local|development/i.test(process.env.REACT_APP_NODE_ENV)
      ? process.env.REACT_APP_PAYRIX_PUBLIC_KEY_DEV
      : process.env.REACT_APP_PAYRIX_PUBLIC_KEY_PROD,
  });

  const completeSale = ({ token, origin }) => {
    setShowSpinner(true);
    httpClient
      .post("/invoice/pay/" + invoiceID, {
        token,
        origin,
      })
      .then((data) => {
        setShowSpinner(false);
        setSale(data);
      })
      .catch((err) => {
        setShowSpinner(false);
        setPaymentError(err?.response?.data?.message);
        warningAlert({ message: err?.response?.data?.message });
      });
  };

  return (
    <>
      <div
        className="uk-background-cover uk-background-norepeat "
        uk-height-viewport="expand"
        style={{ backgroundImage: "url(/Invoice/invoice-bg.png)", zIndex: "-10" }}
      ></div>
      {status == "loading" ? <Loader></Loader> : null}
      {status == "error" ? (
        <ModalWindow position="uk-flex-middle" className="uk-width-medium uk-border-rounded">
          <div className="uk-text-center uk-text-danger uk-card uk-margin-auto uk-width-large uk-padding ">
            <span uk-icon="icon: warning; ratio: 2"></span>
            <div className="uk-text-large">{error?.response?.data?.message}</div>
          </div>
        </ModalWindow>
      ) : null}
      {status == "complete" && /pending/i.test(invoice?.status) && !sale ? (
        <Panel
          mode="token"
          error={paymentError}
          close={noop}
          price={invoice?.price}
          tax={invoice?.tax}
          submitSale={completeSale}
          hideBackButton={true}
          isLoadedPayrix={payrix}
          txnDetails={saleObject.name}
        >
          {storeLogo ? (
            <div>
              <img width="100" src={storeLogo} />
            </div>
          ) : null}
          {saleObject?.salesProducts?.map(({ product, qty, price, itemId }) => {
            return (
              <div className="uk-flex uk-flex-between" key={"products" + itemId}>
                <span>
                  {product} * {qty}
                </span>
                <span>${price}</span>
              </div>
            );
          })}
          <div className="uk-flex uk-flex-between">
            <span>Subtotal</span>
            <span>${to2DP(invoice?.price + discount - invoice?.tax)} USD</span>
          </div>
          <div className="uk-flex uk-flex-between">
            <span>Tax</span>
            <span>${to2DP(invoice?.tax)} USD</span>
          </div>

          {saleObject?.tip ? (
            <div className="uk-flex uk-flex-between">
              <span>Tip</span>
              <span>${to2DP(saleObject?.tip)} USD</span>
            </div>
          ) : null}
          {discount ? (
            <div className="uk-flex uk-flex-between">
              <span>Discount</span>
              <span className="uk-text-success">-${to2DP(discount)} USD</span>
            </div>
          ) : null}
          <hr className="uk-divider-icon" />
          <div className="uk-text-bold uk-flex uk-flex-between">
            <span>Total</span>
            <span>${to2DP(invoice?.price)} USD</span>
          </div>
        </Panel>
      ) : null}
      {status == "complete" && /close/i.test(invoice?.status) ? (
        <SuccessPopup
          position="uk-flex-middle"
          mute="true"
          message={"Invoice paid successfully"}
          caption={`$${to2DP(invoice?.price)}`}
        >
          {saleObject.name}
        </SuccessPopup>
      ) : null}
      {status == "complete" && sale ? (
        <SuccessPopup
          position="uk-flex-middle"
          message={"Payment Successful"}
          caption={`Transaction ID: #${sale?.trasnsactionid}`}
        >
          {sale.name}
        </SuccessPopup>
      ) : null}
    </>
  );
};

export default withSpinner(withAuthentication(CustomerInvoice));
