import React, { useEffect } from "react";
import ModalWindow from "@/custom_components/ModalWindow";

export default function SuccessPopup({ message, caption, position, children, mute = false, color = "#9F1414" }) {
  useEffect(() => {
    const audio = new Audio("/repair/completed.wav");
    try {
      if (!mute) {
        audio.play();
      }
    } catch (error) {}
    return () => audio.pause();
  }, []);
  return (
    <ModalWindow position={position} className="uk-width-medium uk-border-rounded uk-padding-small">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill={color}>
          <path
            d="M85.3553 14.6447C75.9785 5.26784 63.2608 0 50 0C36.7392 0 24.0215 5.26784 14.6447 14.6447C5.26784 24.0215 0 36.7392 0 50C0 63.2608 5.26784 75.9785 14.6447 85.3553C24.0215 94.7322 36.7392 100 50 100C63.2608 100 75.9785 94.7322 85.3553 85.3553C94.7322 75.9785 100 63.2608 100 50C100 36.7392 94.7322 24.0215 85.3553 14.6447ZM50 93.2984C26.1257 93.2984 6.70164 73.8747 6.70164 50C6.70164 26.1253 26.1253 6.70164 50 6.70164C73.8747 6.70164 93.2984 26.1253 93.2984 50C93.2984 73.8747 73.8747 93.2984 50 93.2984Z"
            fill={color}
          />
          <path
            d="M42.465 60.482L27.0906 45.1078L22.3519 49.8464L42.465 69.9595L77.5435 34.8812L72.8046 30.1426L42.465 60.482Z"
            fill={color}
          />
        </svg>
      </div>
      <div className="uk-text-large uk-text-bold">{message}</div>
      <div className="uk-text-medium uk-text-bold">{caption}</div>
      <div>{children}</div>
    </ModalWindow>
  );
}
