import { getActiveShop, to2DP } from "@/constants/utility";
import { withAuthentication } from "@/hoc/withAuthentication";
import withSpinner from "@/hoc/withSpinner";
import { warningAlert } from "@/utility/helpers";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

const fields = ["number", "name", "cvv", "expiration", "address"];

const placeholder = (
  <div className="ph-col-12">
    <div className="ph-row">
      <div className="ph-col-4"></div>
    </div>
  </div>
);

const Form = ({ isLoadedPayrix, mode, error, submitSale, price, tax, user, txnDetails, children }) => {
  const buttonRef = useRef();
  const [btnClass, setBtnClass] = useState("uk-button-secondary");
  const [btnText, setBtnText] = useState("Pay");
  const [loading, setLoading] = useState(false);

  const store = getActiveShop(user);

  useLayoutEffect(() => {
    if (isLoadedPayrix !== "ready") return;

    window.PayFields.fields = fields.map((type) => ({
      type,
      element: `#payrix-${type}`,
    }));

    window.PayFields.customizations = {
      style: {
        // All address fields class.
        // ".address-input": {
        //   borderColor: "rgb(119,136,153)",
        //   borderStyle: "solid",
        //   borderBottomWidth: "1px",
        // },
        // All fields
        ".input": {
          // borderColor: "rgb(69,67,67)",
          // borderStyle: "solid",
          // borderBottomWidth: "1px",
          width: "100%",
        },
        // All error spans
        ".form-error": {
          color: "#a6371e",
        },
        // Address error spans
        ".address-form-error": {
          color: "#a6371e",
        },
      },
    };

    try {
      if (window.PayFields.appended) {
        window.PayFields.reload();
        return;
      }

      setTimeout(() => {
        window.PayFields.appendIframe();
        window.PayFields.addWalletButtons();
      }, 1000);
    } catch (error) {}
  }, [isLoadedPayrix]);

  useEffect(() => {
    if (isLoadedPayrix !== "ready") return;

    window.PayFields.onSuccess = function (response) {
      if (mode == "token") {
        setBtnText("Authorizing...");
      } else {
        setBtnClass("uk-label-success uk-animation-shake");
        setBtnText("Success");
      }

      setTimeout(() => {
        submitSale({
          isSplit: false,
          paymentFrom: "Payrix",
          trasnsactionid: response.data[0].id,
          token: response.data[0].token,
          origin: response.data[0].origin,
        });
      }, 500);
    };

    // On API error(s), this would handled automatically if an button element is
    // passed to Payfields.button
    window.PayFields.onFailure = function (response) {
      // We will flash error response on button

      response.errors?.map(({ msg }) => {
        warningAlert({ message: msg });
      });

      setBtnText("Error");
      setBtnClass("uk-button-danger uk-animation-shake");

      window.PayFields.clearFields();

      setTimeout(function () {
        setBtnText("Pay");
        setBtnClass("uk-button-secondary");

        // Enable button to resubmit
        buttonRef.current.disabled = false;
      }, 2000);
    };

    window.PayFields.onValidationFailure = function () {
      // Enable the button to resubmit
      buttonRef.current.disabled = false;
      setLoading(false);
    };

    window.PayFields.onFinish = function () {
      setLoading(false);
    };
  }, [isLoadedPayrix]);

  return (
    <>
      <div className={"uk-child-width-1-2@m uk-grid-small uk-text-left"} uk-grid={1}>
        <div className="">
          <div className="uk-placeholder">
            <div>
              <label className="uk-form-label" htmlFor="payrix-name">
                Name on card
              </label>
              <div id="payrix-name" className="uk-form-controls form-row"></div>
            </div>
            <div>
              <label className="uk-form-label" htmlFor="payrix-number">
                Card Number
              </label>
              <div id="payrix-number" className="uk-form-controls form-row"></div>
            </div>
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-1-2">
                <label className="uk-form-label" htmlFor="payrix-expiration">
                  Expiration
                </label>
                <div id="payrix-expiration" className="uk-form-controls form-row"></div>
              </div>
              <div className="uk-width-1-2">
                <label className="uk-form-label" htmlFor="payrix-cvv">
                  Cvv
                </label>
                <div id="payrix-cvv" className="uk-form-controls form-row"></div>
              </div>
            </div>

            <div>
              <label className="uk-form-label" htmlFor="payrix-address">
                Billing Information
              </label>
              <div id="payrix-address" className="uk-form-controls address-row"></div>
            </div>
          </div>
        </div>
        {/* transaction summary */}
        <div>
          <div className="uk-card">
            <div className="uk-card-body uk-card-default">
              {children ? (
                <div>{children}</div>
              ) : (
                <>
                  <div>{txnDetails}</div>
                  <div className="uk-flex uk-flex-between">
                    <span>Subtotal</span>
                    <span>${to2DP(price - tax)} USD</span>
                  </div>
                  <div className="uk-flex uk-flex-between">
                    <span>Tax</span>
                    <span>${to2DP(tax)} USD</span>
                  </div>
                  <hr className="uk-divider-icon" />
                  <div className="uk-text-bold uk-flex uk-flex-between">
                    <span>Total</span>
                    <span>${to2DP(price)} USD</span>
                  </div>
                </>
              )}

              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                  setLoading(true);
                  window.PayFields?.submit();
                }}
              >
                <div className="uk-margin-small">
                  <div className="uk-text-muted uk-text-small">
                    <label htmlFor="tos" className="uk-text-small">
                      <input className="uk-checkbox" type="checkbox" name="tos" required={1} /> By clicking I agree and
                      consent to the{" "}
                      <a href="https://spotspos.com/terms" target="_blank">
                        terms of service
                      </a>{" "}
                      &{" "}
                      <a href="https://spotspos.com/terms/#privacyPolicy" target="_blank">
                        Privacy Policy
                      </a>{" "}
                      provided.
                    </label>
                  </div>
                </div>

                <div className="button-container uk-text-center">
                  <button
                    ref={buttonRef}
                    type="submit"
                    id="payrix-button"
                    disabled={loading}
                    className={[
                      "uk-button uk-text-bold uk-text-capitalize uk-button-large uk-width-1-1",
                      btnClass,
                      error ? "uk-button-danger" : "",
                    ].join(" ")}
                  >
                    {loading ? <i className="fa fa-spinner fa-spin fa-fw"></i> : error || btnText}
                  </button>
                </div>
              </form>
            </div>
            <div className="uk-card-footer uk-text-small">
              <div>Available payment methods:</div>
              <div className="uk-flex uk-flex-middle uk-margin-small-bottom">
                <img style={{ width: "40px" }} src="https://test-api.payrix.com/images/card_logos/visa.png" />
                <img style={{ width: "40px" }} src="https://test-api.payrix.com/images/card_logos/master.png" />
                <img style={{ width: "40px" }} src="https://test-api.payrix.com/images/card_logos/amex.png" />
                <img style={{ width: "40px" }} src="https://test-api.payrix.com/images/card_logos/discover.png" />
              </div>
              <div>{store?.address1}</div>
              <div>{store?.address2}</div>
              <div>
                {store?.city}, {store?.state}
              </div>
              <div>{store?.zip}</div>
              <div>{store?.phone}</div>
            </div>
          </div>
        </div>
      </div>

      <style jsx="">
        {`
          #payrix-buttonn {
            text-transform: uppercase;
            cursor: pointer;
            border: none;
            width: 150px;
            outline: none;
            height: 30px;
            background-color: rgb(69, 67, 67);
            color: white;
          }

          .btnSuccess {
            background-color: rgb(79, 138, 16);
            transition: 2s;
          }

          .btnFailure {
            background-color: rgb(138, 15, 15);
            transition: 2s;
          }

          /*
       Recommended minimum div sizes to properly display iframes. We need to
       set div height and width when passing custom styles. When no custom
       styles are passed then it is automatically set to optimal height and
       width
    */
          .form-row {
            height: 50px;
            width: 100%;
          }
          .address-row {
            height: 438px;
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default withAuthentication(withSpinner(Form));
