import React from "react";
import ReactDOM from "react-dom";

const ModalWindow = ({
  position = "uk-flex-top",
  children,
  style = {},
  className = "",
  zIndex = 2,
  hidden,
  overflow = true,
}) => {
  const autoOverflow = overflow ? { "uk-overflow-auto": 1 } : {};

  const element = (
    <div
      id="my-modal"
      className={[" uk-flex uk-open", position].join(" ")}
      uk-modal={1}
      style={zIndex ? { zIndex, padding: 0, margin: 0 } : {}}
      hidden={hidden}
    >
      <div
        className={[className, "uk-modal-dialog uk-text-center"].join(" ")}
        style={{ ...style, minWidth: "380px", borderRadius: "10px" }}
        // {...autoOverflow}
      >
        {children}
      </div>
    </div>
  );

  return ReactDOM.createPortal(element, document.body);
};

export default ModalWindow;
