import { useExternalScript } from "@/hooks/useExternalScript";
import { useEffect } from "react";

const handlePayrixLoaded = (config = {}) => {
  Object.assign(window.PayFields.config, config);
};

const DEV = ["local", "development"];

export const usePayrix = (config) => {
  const jqueryLoaded = useExternalScript("https://code.jquery.com/jquery-2.2.4.min.js");
  const isLoadedPayrix = useExternalScript(
    `https://${DEV.includes(process.env.REACT_APP_NODE_ENV) ? "test-" : ""}api.payrix.com/payFieldsScript`
  );

  useEffect(() => {
    if (isLoadedPayrix !== "ready") return;
    handlePayrixLoaded(config);
  }, [JSON.stringify(config), jqueryLoaded, isLoadedPayrix]);

  return isLoadedPayrix;
};
