import { HttpFactory } from "@/RequestHandler";
import StorageUtils from "@/utility/StorageUtils";
import { useEffect, useMemo, useRef, useState } from "react";

const useFetch = ({ url, type = [], httpClient, method = "GET", body, autoFetch = true }) => {
  const [data, setData] = useState(type);
  const [loading, setLoading] = useState(autoFetch);
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const firstUpdate = useRef(true);

  const requestClient = httpClient || HttpFactory.getInstance();
  !httpClient && requestClient.setToken(StorageUtils.getToken());

  const fetchData = () => {
    switch (true) {
      case /post/i.test(method):
        return requestClient.post(url, body);
      default:
        return requestClient.get(url);
    }
  };

  useEffect(() => {
    let unmounted = false;

    // if (firstUpdate.current && !autoFetch) {
    //   firstUpdate.current = false;
    //   return;
    // }

    if (!autoFetch) {
      return;
    }

    !unmounted && setLoading(true);
    setStatus("loading");

    fetchData()
      .then((data) => {
        if (!unmounted) {
          setData((d) => data);
          setLoading((l) => false);
          setStatus("complete");
          setError((l) => null);
        }
      })
      .catch((error) => {
        if (!unmounted) {
          setLoading((l) => false);
          setStatus("error");
          setData((t) => type);
          setError((l) => error);
        }
      });

    return () => {
      unmounted = true;
    };
  }, [url, JSON.stringify(body), autoFetch, refresh]);

  const reFetch = () => {
    setRefresh((n) => n + 1);
  };

  return { data, setData, loading, error, status, reFetch };
};

export default useFetch;
